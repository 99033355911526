// COLORS
$bright : #ffffff;
$dark: #000000;
$darkgrey: #333333;
$grey: #d0d0d0;
$grey-e: #efefef;
$lightgrey: #f1f1f1;

$red: #CC0033;
$darkred: #971B2F;
$darkblue: #000033;
$blue: #00AFD7;

// FONTS
$main-font: 'Meta Pro', sans-serif;

// ICOMOON
$icon-address: "\e900";
$icon-apply: "\e901";
$icon-arrow: "\e902";
$icon-arrow: "\e903";
$icon-arrow: "\e904";
$icon-arrow: "\e905";
$icon-contact: "\e906";
$icon-down: "\e907";
$icon-download: "\e908";
$icon-left: "\e909";
$icon-linkedin: "\e90a";
$icon-loupe: "\e90b";
$icon-moins: "\e90c";
$icon-offer: "\e90d";
$icon-phone: "\e90e";
$icon-play: "\e90f";
$icon-plus: "\e910";
$icon-right: "\e911";
$icon-send: "\e912";
$icon-tracking: "\e913";
$icon-twitter: "\e914";
$icon-up: "\e915";
$icon-youtube: "\e916";