.bg-video {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

video {
  outline: none;
}

.video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: $bright;
  transition: all 0.5s ease;
  backface-visibility: hidden;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 22px;
    background: url("../../../assets/images/icons/icon-play.svg") center
      no-repeat;
    background-size: 100% auto;
    transition: transform 0.5s ease;
    backface-visibility: hidden;
  }

  &:hover {
    cursor: pointer;
    transform: translate(-50%, -50%) scale(1.1);

    &::after {
      transform: translate(-50%, -50%) scale(0.9);
    }
  }

  .is-playing & {
    opacity: 0;
    visibility: hidden;
  }

  @include m767 {
    width: 55px;
    height: 55px;

    &::after {
      width: 15px;
      height: 17px;
    }
  }
}
