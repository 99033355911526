.layout {
  margin: 0 auto;
  padding: 0 30px;
  max-width: 1370px;
  @include m768 {
    padding: 0 40px;
  }
  @include m1100 {
    padding: 0 70px;
  }
  &_inner {
    margin: 0 auto;
    max-width: 1020px;
    @include m1100 {
      // padding: 0 100px;
    }
  }
}
.scrollLocked {
  @include m1099 {
    position: relative;
    overflow: hidden;
  }
}

.main-wrapper {
  overflow-x: hidden;
  position: relative;
}

.mb-20 {
  margin-bottom: 20px;
}

.text-center {
  text-align: center;
}

.text-left {
  padding-left: 30px;
  margin-top: 90px;
}

.nowrap {
  white-space: nowrap;
}

p {
  color: $darkgrey;
  line-height: 1.5;
}

ul li {
  color: $darkgrey;
  line-height: 1.5;
  position: relative;
  padding-left: 15px;

  &:not(:last-child) {
    margin-bottom: 5px;
  }

  &::before {
    content: "";
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: $darkgrey;
    position: absolute;
    top: 12px;
    left: 0;
  }
}

.row {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

[class^="col-"],
[class*=" col-"] {
}

.col-half {
  width: 100%;

  @include m768 {
    max-width: 50%;

    &:nth-child(odd) {
      padding-right: 20px;
    }
    &:nth-child(even) {
      padding-left: 20px;
    }
  }

  @include m767 {
    &:not(:last-child) {
      margin-bottom: 60px;
    }
  }
}

.col-third {
  width: 100%;
  max-width: 33.333333%;
}
