.m-full-carousel {
  position: relative;
  margin-bottom: 100px;
  &-cross {
    cursor: pointer;
    position: absolute;
    background: url("../../../assets/images/icons/xCross.svg") center no-repeat;
    width: 20px;
    height: 20px;
    top: 0px;
    right: 0px;
    @include m767 {
      width: 20px;
      height: 20px;
      top: -35px;
      right: -25px;
    }
  }

  &__layout {
    @include m767 {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &__content {
    background-color: rgba($bright, 0.8);
    position: absolute;
    top: 50%;
    left: 60px;
    transform: translateY(-50%);
    width: 430px;
    min-height: 230px;
    padding: 25px;

    @include m1199 {
      min-height: 265px;
    }

    @include m1239 {
      max-width: 38%;
    }

    @include m768-991 {
      max-width: 48%;
    }

    @include m767 {
      top: auto;
      bottom: 50px;
      left: 20px;
      right: 20px;
      transform: none;
      max-width: none;
      width: auto;
    }

    @include m399 {
      min-height: 300px;
    }
  }

  &__title {
    color: $red;
    font-size: 24px;
    font-weight: 700;

    &:not(:last-child) {
      margin-bottom: 20px;
    }

    @include m992-1199 {
      font-size: 22px;
    }

    @include m768-991 {
      font-size: 18px;
    }
  }

  &__text {
    color: $darkblue;
  }

  &__thumbnail {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &.is-desktop {
      @include m767 {
        display: none;
      }
    }

    &.is-mobile {
      @include m768 {
        display: none;
      }
    }
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;

    &.is-desktop {
      @include m767 {
        display: none;
      }
    }

    &.is-mobile {
      @include m768 {
        display: none;
      }
    }
  }

  .swiper-slide {
    position: relative;
    height: auto;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      padding-top: 35.05%;
      position: relative;
      width: 100%;
    }

    @include m1199 {
      padding-top: 30px;
      padding-bottom: 30px;
    }

    @include m991 {
      padding-top: 50px;
      padding-bottom: 50px;
    }

    @include m768-991 {
      padding-top: 70px;
      padding-bottom: 70px;

      .video-play {
        left: 77%;
        z-index: 1;
      }
    }

    @include m767 {
      &::before {
        padding-top: 120%;
      }

      .video-play {
        top: 30vw;
        transform: translate(-50%, 0%);
        z-index: 1;
      }
    }

    @include m399 {
      .video-play {
        top: 20vw;
        z-index: 1;
      }
    }
  }

  .swiper-button-next {
    @media (min-width: 1321px) {
      right: auto;
      left: 515px;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    @include m767 {
      display: none;
    }
  }

  .swiper-container {
    .swiper-pagination {
      left: 60px;
      width: 430px;
      bottom: auto;
      top: calc(50% + 140px);

      @include m1239 {
        max-width: 38%;
      }

      @include m768-991 {
        max-width: 48%;
      }

      @include m767 {
        max-width: none;
        width: auto;
        left: 30px;
        right: 30px;
        top: auto;
        bottom: 18px;
      }
    }
  }
}

.m-full-carousel__content,
.m-full-carousel__thumbnail,
.swiper-button-prev,
.swiper-button-next,
.swiper-pagination {
  transition: opacity 0.5s, visibility 0.5s ease;

  // .is-playing & {
  //   opacity: 0;
  //   visibility: hidden;
  // }
}

.is-playing {
  position: absolute;
  z-index: 1;
  border: 1px solid beige;
}
