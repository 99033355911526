#page {
  position: relative;
}

.popup {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  z-index: -1;
  visibility: hidden;
  transition: all 0.6s ease;

  &.is-active {
    opacity: 1;
    visibility: visible;
    z-index: 10;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: #19192a;
    opacity: 0.9;
  }

  &__close {
    align-self: flex-end;
    position: relative;
    // transform: translateY(-100%);
    width: 25px;
    height: 25px;

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 12px;
      left: 0;
      background-color: $bright;
      height: 2px;
      width: 25px;
      border-radius: 12px;
      transition: background-color 0.5s ease;
    }

    &::before {
      transform: rotate(-45deg);
    }
    &::after {
      transform: rotate(45deg);
    }

    &:hover {
      &::before,
      &::after {
        background-color: $red;
      }
    }
  }

  &__content {
    width: 90%;
    max-width: 1200px;
    margin: auto;
  }

  &__video-wrapper {
    position: absolute;
    top: 50%;
    transform: translate3d(0px, -50%, 0px);
    display: flex;
    flex-direction: column;

    @media (max-height: 700px) {
      // padding-top: 5vh;
      // padding-bottom: 5vh;
    }

    iframe {
      height: 90vw * 9 / 16;
      width: 90vw;
      object-fit: cover;
      margin-top: 30px;

      @media (max-height: 700px) {
        top: 6vh;
      }
    }
  }
}
