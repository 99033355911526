.m-thumbnail {
  &__section {
    background-color: $lightgrey;
    padding-top: 120px;
    padding-bottom: 120px;
    position: relative;

    @include m767 {
      padding-top: 90px;
      padding-bottom: 90px;
    }
  }

  &__row  {
    margin-left: -25px;
    margin-right: -25px;

    @include m1239 {
      margin-left: -10px;
      margin-right: -10px;
    }
    @include m767 {
      margin: 0;
    }
  }

  &.col-third {
    padding-left: 25px;
    padding-right: 25px;

    @include m1239 {
      padding-left: 10px;
      padding-right: 10px;
    }
    @include m991 {
      max-width: none;

      &:not(:last-child) {
        margin-bottom: 60px;
      }
    }
    @include m767 {
      padding: 0;
    }
  }

  &__inner {
    height: 100%;
    overflow: hidden;
  }

  .shadow {
    box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.1);
  }

  &__image-wrapper {
    position: relative;
    overflow: hidden;

    &.js-open-video {
      cursor: pointer;
    }
  }

  &__block-link {
    display: block;
  }

  &__image {
    display: block;
    width: 100%;
  }

  &__label {
    position: absolute;
    top: 20px;
    left: -5px;
    color: $bright;
    font-size: 16px;
    font-weight: 700;
    padding: 6px 10px 6px 15px;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: $blue;
      z-index: -1;
      transform: skew(-15deg);
    }
  }

  &__content {
    background-color: $bright;
    height: 100%;
    padding: 30px;
    position: relative;

    @include m1239 {
      padding: 30px 20px;
    }

    .m-thumbnail__block-link::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
    }
  }

  .has-link {
    overflow: hidden;
    display: block;
    position: relative;

    .m-thumbnail__image {
      transition: transform 0.5s ease;
    }

    &:hover {
      .m-thumbnail__image {
        transform: scale(1.1);
      }

      .m-thumbnail__title {
        color: $darkgrey;
        text-decoration: underline;
      }
    }

    .link::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &__title {
    display: block;
    color: $red;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;
    transition: all 0.2s ease;
  }

  .link {
    margin-top: 15px;
  }
}
