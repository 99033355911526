.m-icon-text {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  position: relative;

  &__section {
    padding-top: 120px;
    padding-bottom: 120px;

    @include m767 {
      padding-top: 90px;
      padding-bottom: 90px;
    }
  }

  &__icon {
    display: block;
    border-radius: 50%;
    width: 110px;
    height: 110px;
    background-color: $red;
    position: relative;

    img {
      width: 70px;
      height: 70px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);

      &.icon-aides {
        width: 60px;
        height: 60px;
      }
    }

    @include m991 {
      width: 80px;
      height: 80px;

      img {
        width: 50px;
        height: 50px;

        &.icon-aides {
          width: 40px;
          height: 40px;
        }
      }
    }

    @include m767 {
      position: absolute;
      top: 0;
      left: 0;
    }

    @include m399 {
      width: 70px;
      height: 70px;
    }
  }

  &__content {
    padding-left: 15px;
    width: calc(100% - 120px);
    position: relative;
    
    .button {
      margin-top: 15px;
    }

    @include m991 {
      width: calc(100% - 80px);

      .button {
        padding: 20px;
      }
    }

    @include m768 {
      padding-bottom: 75px;

      .button {
        position: absolute;
        left: 15px;
        bottom: 0;
      }
    }

    @include m767 {
      width: 100%;
      padding-left: 0;

      .button {
        margin-top: 20px;
      }
    }
  }

  &__title {
    color: $darkgrey;
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 20px;

    @include m767 {
      margin-left: 100px;
      margin-top: 10px;
      margin-bottom: 30px;
    }

    @include m399 {
      font-size: 20px;
      line-height: 1.3;
      margin-bottom: 20px;
      margin-left: 80px;
      margin-top: 0;
    }
  }
}