.m-reviews {
  &__section {
    background-color: $red;
    padding-top: 120px;
    padding-bottom: 120px;

    @include m767 {
      padding-top: 90px;
      padding-bottom: 90px;
    }

    .layout_inner {
      @include m1199 {
        padding-left: 60px;
        padding-right: 60px;
      }
      @include m767 {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }

  &__main-title {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;

    @include m767 {
      padding-left: 5px;
      padding-right: 5px;

      br { display: none; }
    }
  }

  &__image {
    width: 100%;
    position: relative;
    @include m768 {
      max-width: 50%;
    }

    img {
      height: 100%;
      width: auto;
      max-width: none;
    }

    @include m1199 {
      img {
        width: 100%;
        object-fit: cover;
      }
    }

    @include m767 {
      img {
        height: auto;
      }
    }
  }

  &__content {
    width: 100%;
    background-color: $bright;
    padding: 60px 40px 30px;
    position: relative;
    // @include m768 {
    //   max-width: 50%;
    // }

    // arrow
    // &::after {
    //   content: '';
    //   position: absolute;
    //   top: 50%;
    //   left: 0;
    //   border-top: 10px solid transparent;
    //   border-bottom: 10px solid transparent;
    //   border-right: 10px solid $bright;
    //   transform: translate(-100%, -50%);
    //   z-index: 1;
    // }

    @include m991 {
      padding-top: 40px;
    }

    @include m767 {
      padding-left: 30px;
      padding-right: 30px;

      &::after {
        top: 0;
        left: 50%;
        transform: translate(-100%, -70%) rotate(90deg);
      }
    }
  }

  &__title {
    display: inline-block;
    color: $red;
    font-size: 30px;
    font-style: italic;
    line-height: 1;
    margin-bottom: 20px;
    position: relative;

    &::before,
    &::after {
      color: $red;
      font-size: 40px;
      font-weight: 700;
      font-style: italic;
      opacity: 0.25;
    }

    &::before {
      content: '“';
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-80%,-50%);
    }

    &::after {
      content: '”';
      display: inline-block;
      position: relative;
      top: 8px;
      line-height: 0.5;
      margin-left: 5px;
    }

    @include m991 {
      font-size: 25px;
    }
    @include m399 {
      font-size: 22px;
    }
  }

  &__author {
    color: $red;
    font-weight: 500;

    &-wrapper {
      margin-top: 20px;
    }

    small {
      font-size: 14px;
      font-style: italic;
      font-weight: 400;
    }
  }

  &__swiper-wrapper {
    position: relative;

    .swiper-slide {
      height: auto;

      & > .row {
        height: 100%;
      }

      @include m767 {
        background-color: $bright;

        & > .row {
          display: block;
        }
      }
    }

    .m-reviews__pagination {
      position: relative;
    }
  }

  &__pagination {
    width: 100%;
    margin-top: 40px;

    @include m767 {
      margin-top: 20px;
    }
  }

  &__nav-button {
    &.swiper-button-prev {
      left: -40px;
      transform: translate(-100%, -30px);

      @include m1199 {
        left: -20px;
      }
    }
    &.swiper-button-next {
      right: -40px;
      transform: translate(100%, -30px);

      @include m1199 {
        right: -20px;
      }
    }

    @include m767 {
      display: none !important;
    }
  }
}
