.header {
  padding: 10px 0;
  color: $dark;
  border-bottom: 1px solid $dark;
  &_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
  }
  &_logo {
    height: 60px;
  }
  &_nav {
    &-list {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    &-item {
      display: block;
      padding: 5px 10px;
    }
  }
  &_lang {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 70px;
    &-btn {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 30px;
      color: $dark;
      border: 1px solid transparent;
      font-size: 14px;
      opacity: .5;
      transition: all ease-out 200ms;
      &[data-lang-active="true"] {
        border: 1px solid $dark;
        opacity: 1;
      }
      @include m1100 {
        &:hover {
          border: 1px solid $dark;
          background-color: $grey;
          opacity: 1;
        }
      }
    }
  }
}