.m-arguments {
  &__layout {
    background-color: $lightgrey;
    padding-bottom: 130px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      height: 280px;
      background-color: $lightgrey;
      transform: translateY(-100%);
    }

    @include m1099 {
      padding-left: 30px;
      padding-right: 30px;
    }
    @include m767 {
      padding-right: 15px;
      padding-bottom: 90px;
    }
  }

  &__header {
    max-width: 800px;
    margin: 0 auto;
    @include m767 {
      padding-right: 15px;
    }
  }

  &__suptitle {
    color: $red;
    font-size: 20px;
    font-style: italic;
    text-align: center;
    margin-bottom: 20px;
  }

  &__main-title {
    @extend .section-title;
  }

  &-block {
    margin-bottom: 60px;
    position: relative;

    &__content {
      background-color: $bright;
      position: relative;
      padding: 50px 60px 60px 40px;
      width: calc(50% - 25px);
      z-index: 1;

      @include m991 {
        padding: 30px 40px 30px 30px;
      }

      @include m768 {
        margin-top: 50px;
      }

      @include m767 {
        width: calc(100% - 12px);
        transform: translateX(-30px);
        padding-right: 25px;
      }

      // red triangle
      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 0; 
        height: 0;
        border-left: 35px solid transparent;
        border-right: 35px solid transparent;
        border-top: 35px solid $darkred;
        transform: translate(-2px, -85%) rotate(45deg);

        @include m767 {
          top: auto;
          bottom: 0;
          border-left-width: 30px;
          border-right-width: 30px;
          border-top-width: 30px;
          transform: translate(40px, -17px) rotate(135deg);
        }
      }
    }

    &__icon {
      display: block;
      width: 70px;
      height: 70px;
      margin-bottom: 15px;
    }

    &__title {
      color: $red;
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 30px;
    }

    &__text {
      line-height: 1.8;
    }

    &__image {
      width: 100%;

      @include m768 {
        position: absolute;
        top: 0;
        right: 0;
        width: calc(50% + 75px);

        // Image 1
        .m-arguments-block-1 & {
          right: -30px;
          width: calc(50% + 105px);
        }

        .is-mobile { display: none; }
      }

      @include m767 {
        margin-top: -42px;
        text-align: right;

        img { display: inline-block; }

        .is-desktop { display: none; }

        .m-arguments-block-1 &,
        .m-arguments-block-2 & {
          position: relative;
          right: -3%;
        }
        .m-arguments-block-3 & {
          padding-left: 3%;
          position: relative;
        }
      }
    }

    // Reverse
    &:nth-child(even) {
      @include m768 {
        justify-content: flex-end;
        margin-bottom: 120px;

        .m-arguments-block {
          &__image {
            top: auto;
            bottom: -50px;
            right: auto;
            left: 0;
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            align-items: flex-end;
          }
          &__content {
            margin-top: 0;

            &::before {
              border-top-color: $red;
              top: auto;
              right: auto;
              bottom: 0;
              left: 0;
              transform: translate(3px, 87%) rotate(-135deg);
            }
          }
        }
      }
    }
  }
}