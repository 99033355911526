.m-thumbnail-slider {
  height: 100%;

  &__section {
    padding-top: 120px;
    padding-bottom: 120px;
    @include m767 {
      padding-top: 90px;
      padding-bottom: 90px;
    }

    .layout_inner {
      padding-left: 30px;
      padding-right: 30px;
      @include m768-1099 {
        padding-left: 40px;
        padding-right: 40px;
      }
      @include m767 {
        padding-left: 20px;
        padding-right: 20px;
      }
    }

    @include m1099 {
      .m-reviews__nav-button.swiper-button-prev {
        left: -3px;
      }
      .m-reviews__nav-button.swiper-button-next {
        right: -3px;
      }
    }
  }

  &__main-title {
    margin-bottom: 20px;
  }

  &__header {
    max-width: 640px;
    margin: 0 auto 50px;
    text-align: center;
    @include m767 {
      margin-bottom: 20px;
    }
  }

  &__swiper-wrapper {
    margin-bottom: 40px;

    .swiper-container {
      padding: 10px;
    }

    .swiper-pagination {
      margin-top: 20px;
    }

    .swiper-pagination-bullet-active {
      background-color: $red;
    }

    .swiper-button-prev,
    .swiper-button-next {
      &::after {
        color: $red;
      }
    }
  }

  &__image {
    display: block;
    width: 100%;
  }
}
