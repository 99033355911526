.button {
  display: inline-block;
  color: $bright;
  border-radius: 2px;
  background-color: $red;
  font-weight: 700;
  padding: 20px 30px;
  text-align: center;
  transition: all 0.5s ease;

  &:hover {
    background-color: $darkred;
  }

  &.border {
    background-color: transparent;
    border: 1px solid $red;
    color: $red;

    &:hover {
      background-color: $grey-e;
      // border-color: $grey-e;
      // color: $bright;
    }
  }
}

.link {
  display: inline-block;
  color: $red;
  font-size: 16px;
  font-weight: 500;

  &.has-arrow {
    &::after {
      content: '';
      display: inline-block;
      vertical-align: middle;
      margin-left: 10px;
      width: 11px;
      height: 8px;
      background: url('../../../assets/images/icons/icon-arrow.svg') center no-repeat;
      background-size: 100% auto;
      position: relative;
      top: -1px;
      transition: transform 0.5s ease;
    }

    &:hover::after {
      transform: translateX(5px);
    }
  }
}