.m-text-video {
  &-row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    @include m768 {
      flex-wrap: nowrap;
    }
  }
  &__modal {
    position: absolute;
    // @include m768 {
    //   position: fixed;
    // }
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.75);
    z-index: 11;

    &--type2 {
      width: 100vw;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: auto;
      padding: 60px 0;

      @include m992 {
        display: flex;
        top: auto;
        justify-content: center;
        transform: translateX(-50%);
        padding: 40px 0;
      }

      .m-text-video__modal-relative {
        position: relative;
        left: auto;
        top: auto;
        transform: none;

        @include m992 {
          width: 700px;
        }
        @include m991 {
          span {
            width: 20px;
            height: 20px;
            top: -30px;
            right: 30px;
          }
        }
      }
    }

    &-relative {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      @include m991{
        max-width: 100%;
      }
      
      span {
        cursor: pointer;
        position: absolute;
        background: url("../../../assets/images/icons/xCross.svg") center no-repeat;
        width: 20px;
        height: 20px;
        top: 0px;
        right: -40px;
        @include m767 {
          width: 20px;
          height: 20px;
          top: -35px;
          right: -25px;
        }

        .m-full-carousel & {
          @include m992 {
            top: 110px;
            right: -30px;
          }
        }
      }

      &-video__carrousel {
        display: block;
        width: 65vw;

        @include m1024 {
          min-height: 370px;
        }
        @include m767 {
          width: 80vw;
          min-height: 300px;
        }
      }
      &-blockvideo {
        width: 100%;
        @include m1024 {
          width: 900px;
        }
        @include m992 {
          width: 700px;
        }

        &-video {
          display: block;
          width: 100%;
          min-height: 300px;
          @include m991{
            max-width: 100%;
          }
          @include m1024 {
            min-height: 550px;
          }
        }
      }
    }
  }
  &__section {
    background-color: $lightgrey;
    padding-top: 120px;
    padding-bottom: 120px;
    position: relative;
    @include m767 {
      padding-top: 90px;
      padding-bottom: 90px;
    }

    .layout_inner {
      @include m1199 {
        padding-left: 40px;
        padding-right: 40px;
      }
      @include m767 {
        padding-left: 30px;
        padding-right: 30px;
      }
    }
  }

  &__content {
    background-color: $red;
    color: $bright;
    padding: 170px 30px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    position: relative;
    @include m991 {
      padding: 40px 20px;
    }
    @include m768 {
      max-width: 38%;
    }

    p {
      color: $bright;
    }

    // arrow
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid $red;
      transform: translate(100%, -50%);
      z-index: 1;

      @include m767 {
        top: auto;
        bottom: 0;
        right: 50%;
        transform: translate(50%, 70%) rotate(90deg);
      }
    }
  }

  &__video-wrapper {
    width: 100%;
    position: relative;
    overflow: hidden;
    @include m768 {
      max-width: 62%;
    }

    &:before {
      content: "";
      display: block;
      position: relative;
      width: 100%;
    }
  }

  .video-wrapper {
    cursor: pointer;
  }

  &__thumbnail {
    width: 62%;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    transition: opacity 0.5s, visibility 0.5s ease;
    @include m767 {
      width: 100%;
      height: 200px;
    }
  }
}
