// Book
@font-face {
  font-family: 'Meta Pro';
  src: url('MetaPro-Book.woff2') format('woff2'),
      url('MetaPro-Book.woff') format('woff'),
      url('MetaPro-Book.svg#MetaPro-Book') format('svg'),
      url('MetaPro-Book.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

// Book Italic
@font-face {
  font-family: 'Meta Pro';
  src: url('MetaPro-BookIta.woff2') format('woff2'),
      url('MetaPro-BookIta.woff') format('woff'),
      url('MetaPro-BookIta.svg#MetaPro-BookIta') format('svg'),
      url('MetaPro-BookIta.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

// Medium
@font-face {
  font-family: 'Meta Pro';
  src: url('MetaPro-Medi.woff2') format('woff2'),
      url('MetaPro-Medi.woff') format('woff'),
      url('MetaPro-Medi.svg#MetaPro-Medi') format('svg'),
      url('MetaPro-Medi.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

// Bold
@font-face {
  font-family: 'Meta Pro';
  src: url('MetaPro-Bold.woff2') format('woff2'),
      url('MetaPro-Bold.woff') format('woff'),
      url('MetaPro-Bold.svg#MetaPro-Bold') format('svg'),
      url('MetaPro-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
