.m-best-sellers {
  &__section {
    padding-top: 120px;
    padding-bottom: 120px;
    position: relative;

    @include m767 {
      padding-top: 90px;
      padding-bottom: 90px;
    }
  }

  &__list-wrapper {
    margin-bottom: 50px !important;
    position: relative;
    @include m767 {
      margin-bottom: 70px !important;
    }

    .swiper-slide {
      height: auto;
    }

    .m-thumbnail {
      height: 100%;
    }

    .swiper-pagination {
      @include m768 {
        display: none;
      }
    }
  }
}
