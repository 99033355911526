.section-title {
  color: $darkblue;
  font-size: 35px;
  font-weight: 500;
  text-align: center;
  margin-bottom: 50px;

  &.is-white {
    color: $bright;
  }

  @include m767 {
    font-size: 26px;
  }
}

.title {
	font-size: 24px;
	font-weight: 700;
	margin-bottom: 20px;

  &.is-white {
    color: $bright;
  }
}