.swiper {
  .swiper-button-prev,
  .swiper-button-next {
    outline: none;
    

    .m-full-carousel__layout &{
      display: none;
    }
    
    &::after {
      color: $bright;
      font-size: 38px;
    }
  }

  .swiper-pagination-bullet {
    margin: 0 4px;
    opacity: 0.4;
    outline: none;

    .m-full-carousel__layout &{
      display: none;
    }
  }

  .swiper-pagination-bullet-active {
    background-color: $bright;
    opacity: 1;
    
    .m-full-carousel__layout &{
      display: none;
    }
  }
}